/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { alpha, buttonClasses, iconButtonClasses } from '@mui/material'
import { amber, grey } from '@mui/material/colors'
import type { ThemeOptions } from '@mui/material/styles'
import { createTheme, getContrastRatio } from '@mui/material/styles'
import screenfull from 'screenfull'

import typography from './typography'

const primaryColors = {
    default: '#276c76',
    drg: '#1da1f2',
    dynacad: '#2c2a28',
    varian: '#00A9E0'
}

const linkColors = {
    default: '#5CAEBC',
    drg: 'rgb(110, 182, 227)',
    dynacad: '#ac7c0a',
    varian: '#00A9E0'
}

const secondaryColors = {
    dynacad: amber[300],
    default: grey[50]
}

export function getPrimaryColor(portal) {
    return primaryColors[portal] || primaryColors.default
}

function getSecondaryColor(portal) {
    return secondaryColors[portal] || secondaryColors.default
}

function portalHasDarkPrimaryColor(portal) {
    return getContrastRatio(getPrimaryColor(portal), '#000') < 2
}

function isWhiteBackgroundColor() {
    const c = window.getComputedStyle(document.body, null).getPropertyValue('background-color')
    return c.match(/^(?:white|#fff(?:fff)?|rgba?\(\s*255\s*,\s*255\s*,\s*255\s*(?:,\s*1\s*)?\))$/i)
}

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        borderedButton: string
        button: {
            background: string
            color: string
        }
        footer: {
            border: string
            text: string
        }
        invertImage?: string
        link: {
            background: string
        }
        navbar: {
            backgroundColor: string
        }
    }
    interface TypeBackground {
        card: string
    }
}

export const themeOptions: (portal: string) => ThemeOptions = portal => ({
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained'
            },
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    [`&.${buttonClasses.text}.${buttonClasses.colorPrimary}`]: {
                        color: portalHasDarkPrimaryColor(portal) ? 'white' : undefined
                    },
                    [`&.${buttonClasses.outlined}.${buttonClasses.colorPrimary}`]: {
                        color: portalHasDarkPrimaryColor(portal) ? 'white' : undefined,
                        borderColor: portalHasDarkPrimaryColor(portal) ? alpha('#fff', 0.25) : undefined
                    }
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiFormLabel: {
            defaultProps: {
                color: portalHasDarkPrimaryColor(portal) ? 'secondary' : 'primary'
            },
            styleOverrides: {
                root: {
                    marginBottom: '4px'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    [`&.${iconButtonClasses.colorPrimary}`]: {
                        color: portalHasDarkPrimaryColor(portal) ? `${secondaryColors[portal]}aa` : undefined
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    // Cover white fill color set by chrome webkit on autofill fields
                    // by rendering an inner box shadow over the complete field.
                    // E.g. see https://github.com/mui-org/material-ui/issues/283
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 100px ${isWhiteBackgroundColor() ? '#F7F7F7' : '#202020'} inset !important`,
                        WebkitTextFillColor: `${isWhiteBackgroundColor() ? 'black' : getSecondaryColor(portal)} !important`,
                        transition: 'background-color 0s ease-in-out 0s !important'
                    }
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'none'
            },
            styleOverrides: {
                root: {
                    color: linkColors[portal] || linkColors.default
                }
            }
        },
        MuiMenu: {
            defaultProps: {
                container: () => screenfull.element
            }
        },
        MuiModal: {
            defaultProps: {
                container: () => screenfull.element
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                size: 'small'
            }
        },
        MuiSelect: {
            defaultProps: {
                color: portalHasDarkPrimaryColor(portal) ? 'secondary' : 'primary'
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                selectLabel: {
                    marginBottom: 0
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                color: portalHasDarkPrimaryColor(portal) ? 'secondary' : 'primary'
            }
        },
        MuiTooltip: {
            defaultProps: {
                PopperProps: { container: () => screenfull.element }
            }
        },
        MuiTypography: {
            styleOverrides: typography
        }
    },
    palette: {
        mode: 'dark',
        primary: { main: getPrimaryColor(portal) },
        secondary: {
            main: getSecondaryColor(portal)
        },
        background: {
            default: '#1D1D1F',
            paper: '#313032',
            card: '#313032'
        },
        footer: {
            text: '#a0a0a0'
        },
        button: {
            background: '#555',
            color: '#fff'
        },
        link: {
            background: '#373737'
        },
        borderedButton: '#ffffff26',
        navbar: {
            backgroundColor: getPrimaryColor(portal)
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobileSmall: 0,
            mobileMedium: 414,
            mobileLarge: 630,
            tabletSmall: 768,
            tabletMedium: 850,
            tabletLarge: 990,
            desktopSmall: 1280,
            desktopMedium: 1400,
            desktopLarge: 1680
        }
    }
})

export const currentPortal = (portal?: string) => portal || window.location.pathname.split('/')[1]

export const createDarkThemeForOtherPortalsThanDRG = (portal?: string) =>
    createTheme(themeOptions(currentPortal(portal)) as any)
